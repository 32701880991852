<script>
import { menuTypeEnum } from '_e/index'
import MenuManage from '_platform/view/permission/components/MenuManage'
import routerList from '_platform/router/dynamicRoutes'
export default {
  name: 'MerchantsMenu',
  menuTypeEnum,
  render (h) {
    return h(MenuManage, {
      props: {
        routerList,
        menuType: menuTypeEnum.MERCHANT.key
      }
    })
  }
}
</script>
